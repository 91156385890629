import $ from 'jquery'
import stage from './stage'

class Stick {

  constructor() {
    this.init()
  }

  init() {

     this.box = $('.box');
     this.renderQue = this.render.bind(this)
     this.resizeQue = this.resize.bind(this)
     stage.on("stage:scroll", this.renderQue);
     stage.on("stage:resize", this.resizeQue);
     this.resize();

  }


  resize(e){

    if(!e)
      e = stage

    this.box.each((i, el) => {

      const box = $(el)
      const rect = box.offset()
      const width = box.width()
      const height = box.height()


      box.data('bottom', rect.top  + height)
      box.data('top', rect.top )
      box.data('left', rect.left)
      box.data('width', width)
      box.data('height', height)

    })

    this.render(e)

  }

  render(e) {
    this.box.each((i, el) => {
      const box = $(el)
      const index = box.data('height')
      const token = box.data('top')
      const inview =  e.scrolltop + e.h > token && e.scrolltop < token + index;
      const loading = e.scrolltop <= token - e.h;

      box.toggleClass('in-view', inview)
      box.toggleClass('hide', loading)
      })

	}

  destroy(){
    stage.removeListener("stage:scroll", this.renderQue)
		stage.removeListener("stage:resize", this.resizeQue)
  }
}

module.exports = Stick
