import $ from 'jquery'

import Project from './components/project'
import Sticky from './components/sticky'
import stage from './components/stage'
import gsap from 'gsap'
import './jquery.smoothState'

window.lazySizesConfig = window.lazySizesConfig || {};
lazySizesConfig.lazyClass = 'lazy';
lazySizesConfig.loadingClass = 'lazyloading';
lazySizesConfig.loadedClass = 'lazyloaded';
lazySizesConfig.loadMode = 1;
lazySizesConfig.preloadAfterLoad = true

import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/respimg/ls.respimg'
import 'lazysizes'

class App {

  constructor() {
    this.init()
  }

  init() {

    this.isproject = $('.project-content').length > 0
    $('body').addClass('loaded')

    this.setupSmoothState()
    this.initmodules()
    this.id = $('.project-content').attr("id")

    $('.branding a').on('click', (e) =>{
      e.preventDefault()
      var href = $(e.currentTarget).attr('href');
      this.smoothState.load(href);
    })

    $('.info').on('click', (e) =>{
      e.preventDefault()
      $('body').addClass('has-info')
    })

    $('.button-close').on('click', (e) =>{
      e.preventDefault()
      $('body').removeClass('has-info')
    })

  }

  setupSmoothState() {
    var failuresLink = {
      prefetch : true,
      forms : false,
      cacheLength: 100,
      scroll: true,
      comunicator: this,
      blacklist : '[href*="/wp-"], a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"], a[href$=".pdf"]',
      onStart : {
        duration : 900,
        render : this.onTransitionStarted.bind(this)
      },
      onReady : {
        duration : 950,
        render : this.onTransitionReady.bind(this)
      },
      onAfter : this.onTransitionAfter.bind(this)
    };
    this.smoothState = $("#pjax-container").smoothState(failuresLink).data("smoothState");
    //console.log(this.smoothState);
  }

  onTransitionStarted(container) {

    console.log(container);

    //Page OUT

  //  this.lastscroll = stage.scrolltop != 0 ? stage.scrolltop : this.lastscroll

    if(this.isproject){
      this.animateProjectOut(container)
    }
    else{
      this.animateHomeOut(container)
    }

    //TweenMax.to(container, .950, {autoAlpha: 0, x: -stage.w})

  }

  onTransitionReady(container, newContent) {

    //Page IN

    this.deinit();
    container.html(newContent);

    TweenMax.set(container, {autoAlpha: 1, x: 0})
    this.isproject = newContent.hasClass('project-content');


    if(this.isproject){
      this.animateProjectIn(container)
      this.id = newContent.attr("id")
    }
    else{
      console.log($('#' + this.id), this.id)
      var currentId = $('#' + this.id).offset().top - 120
      $(window).scrollTop(currentId)
      this.animateHomeIn(container)
    }

    //$('body').toggleClass('shrink', this.isproject);


  }


  onTransitionAfter() {

    //Page Done

    this.initmodules();

    if (window.ga) {
      if (ga.create) {
        ga("set", {
          page : window.location.pathname,
          title : document.title
        });
        ga("send", "pageview");
      }
    }
  }

  animateHomeIn(container){
    //console.log('HOME - IN');
    //cover , container, footer
    TweenMax.from(container, .950, {autoAlpha: 0, x: -stage.w, onComplete: this.onComplete.bind(this), clearProps: 'all'})
  }

  animateHomeOut(container){
    //console.log('HOME - OUT');
    //cover , container, footer

    this.shrinkLogo(!this.nextishome)
    TweenMax.to(container, .950, {autoAlpha: 0, x: -stage.w, onComplete: this.onComplete.bind(this)})
  }

  onComplete(){
    //container && container.removeClass('homein')
  }

  animateProjectIn(container){
    //console.log('PROJECT - IN');

    var slider = $('.slider', container)
    var toolbar = $('.toolbar', container)

    TweenMax.from(slider, .950, {autoAlpha: 0, onComplete: this.onComplete.bind(this), clearProps: 'all'})

    TweenMax.from(toolbar, .950, { x: 120, onComplete: this.onComplete.bind(this), clearProps: 'all'})
    // slider , toolbar

  }

  animateProjectOut(container){
  ///  console.log('PROJECT - OUT');
    if (this.nextishome) {
      this.shrinkLogo()
    }

    var slider = $('.slider', container)
    var toolbar = $('.toolbar', container)

    if (stage.w > 767){
      TweenMax.to(slider, .950, {autoAlpha: 0,  onComplete: this.onComplete.bind(this), clearProps: 'all'})
      TweenMax.to(toolbar, .950, { x: 120, onComplete: this.onComplete.bind(this), clearProps: 'all'})
    }
    else {
      TweenMax.to(slider, .950, {autoAlpha: 0,  onComplete: this.onComplete.bind(this), clearProps: 'all'})
      TweenMax.to(toolbar, .950, { autoAlpha: 0, onComplete: this.onComplete.bind(this), clearProps: 'all'})
    }


    // slider , toolbar
  }

  shrinkLogo(isproject){
    // branding, info
    //var right = isproject ? 58 : '50%'
    //var from = isproject ? '50%' : 58
    //TweenMax.set($('.branding'), { right: from})
    $('.musthead').toggleClass('musthead--project', isproject)
    //TweenMax.to($('.branding'), 1 , { right: right} )
  }


  deinit(){
    //console.log('init destroy')
    if(this.initialized) {
      this.sticky.destroy()
      this.project.destroy()
      this.initialized = false
    }

  }

  initmodules(){
    //console.log('init project')
    if(!this.initialized) {
      this.sticky = new Sticky()
      this.project = new Project()
      this.initialized = true
    }
  }

  checkLink(url){
    //console.log(url)
    var target = url.replace('http://victoria-bain.com', '')
    target = target.replace('http://www.victoria-bain.com', '')
    target = target.replace('https://www.victoria-bain.com', '')
    target = target.replace('https://victoria-bain.com', '')
    this.nextishome = target == '/admin' || target == '/admin/' || target == '/'

  }

}

module.exports = App
