import $ from 'jquery'
var slick = require("../slick");
import stage from './stage'

class Project {

  constructor() {
    this.init()
  }

  init() {


    this.addEvents()
    this.current = 0
    this.addSlider()


  }

  addEvents() {

      $('.share').on('click', (e)=>{
        e.preventDefault()
        $('.toolbar').addClass('has-share')
      })

      $('.share-twitter').on('click', (e)=>{
        e.preventDefault()
        var link = "Victoria Bain " + window.location.href
        var url = "https://twitter.com/home?status=" + encodeURIComponent(link);
        window.open(url, "twitter")
      })

      $('.share-facebook').on('click', (e)=>{
        e.preventDefault()
        var link = window.location.href
        var url = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(link);
        window.open(url, "facebook")
      })

      var link = "Victoria Bain&body=" + window.location.href
      var uri = "mailto:?subject=" + link;
      $('.share-email').attr('href', uri)

  }


  addSlider(){

    console.log('init slider')

    $('.slider').slick({
      infinite: false,
      slidesToShow: 1,
      variableWidth: false,
      initialSlide: 0,
      prevArrow: $('.slick-prev'),
      nextArrow: $('.slick-next'),
      responsive: [
        {
          breakpoint: 767,
          settings: "unslick"
        }
      ]
    });

    $('.slider').on('afterChange', (event, slick, currentSlide) => {
       this.current = currentSlide
    });

    $('.slick-list').on('click', (e) => {
      var m = (stage.w - 120)/2 - e.clientX

      if(m < 0 ){
        $('.slider').slick('slickNext');
      }
      else {
        $('.slider').slick('slickPrev');
      }
    })

    $('.slick-list').on('mousemove', (e) => {
      var m = (stage.w - 120)/2 - e.clientX
      $('.page').toggleClass('w-resize', m >= 0 && this.current > 0 )
      $('.page').toggleClass('e-resize', m < 0)
    })

  }

  destroy(){
    console.log('destroy project')
    $('.share').off('click')
    $('.slick-list').off('click')
    $('.slick-list').off('mousemove')
    $('.slider').slick('unslick');
  }


}

module.exports = Project
