import $ from 'jquery'

import App from './app'

$(window).on('load', function() {

    setTimeout(() => {

      const app = new App()

    }, 100)

});
